const state = () => {
  return {
    values: {},
  };
};
const mutations = {
  setValues(state, values) {
    state.values = values;
  },
};
const getters = {
  hasValues: (state) => {
      return !!state.values;
  },

  getLogbookActionTypes: (state) => {
    console.log("getLogbookActionTypes", state.values.logbook.actions);
    return state.values.logbook?.actions
      ?.map((type) => ({
        name: type.value,
        value: type.key,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  getLogbookActionType: (state) => (actionTypeKey) => {
    const actionTypes = state.values.logbook?.actions;
    const actionType = actionTypes?.find((tmpActionType) => {
      return tmpActionType.key == actionTypeKey;
    });
    return actionType?.value;
  },

  getBookingUtilizations: (state) => {
    return state.values.booking?.utilization
      ?.map((type) => ({
        name: type.value,
        value: type.key,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  getBookingUtilization: (state) => (utilKey) => {
    const utilizations = state.values.booking?.utilization;
    const utilization = utilizations.find((tmpUtil) => {
      return tmpUtil.key == utilKey;
    });
    return utilization?.value;
  },

  getProviderLanguages: (state) => {
    return state.values.user?.language
        ?.map((type) => ({
          name: type.value,
          value: type.key,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
  },

  getProviderPaymentMethods: (state) => {
    return state.values.payment?.availableMethods
        ?.map((type) => ({
          name: type.value,
          value: type.key,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
  },

    getGenders: (state) => {
        return state.values.user?.gender
            ?.map((type) => ({
                name: type.value,
                value: parseInt(type.key),
            }))
    },
};

export default {
  state,
  mutations,
  getters,
};
