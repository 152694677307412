const state = () => {
    return {
        version: process.env.VUE_APP_VERSION || null,
    }
};

const mutations = {
    refreshVersion(state) {
        state.version = process.env.VUE_APP_VERSION || null;
    },
};

const getters = {
    getAppVersion: (state) => {
        return state.version;
    },
};

export default {
    state,
    mutations,
    getters,
}