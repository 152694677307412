import Unauthenticated from "../layouts/Unauthenticated";
import Default from "../layouts/Default";

const authenticated = {
  path: "",
  redirect: "dashboard",
  // component: (typeof window !== 'undefined' && window.ReactNativeWebView) ? Mobile : Standard,
  component: Default,
  children: [
    {
      path: "dashboard",
      name: "dashboard",
      component: () => import("../pages/Dashboard.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-dashboard",
      },
    },
    {
      path: "bookings/new-select",
      name: "new-booking-select",
      component: () => import("../pages/Booking/Create/BookingTypeSelect.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-newBookingSelect",
      },
    },
    // TODO Weiche für Buchungsarten
    {
      path: "bookings/new-by-vehicle",
      name: "new-flex-booking",
      component: () => import("../pages/Booking/Create/FlexBookingCreate.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-newByVehicle",
      },
    },
    {
      path: "bookings/new-by-date",
      name: "new-date-booking",
      component: () => import("../pages/Booking/Create/BookingCreate.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-newByDate",
      },
    },
    {
      path: "bookings/new-by-calendar",
      name: "book-by-calendar",
      component: () => import("../pages/Booking/Create/BookingCalendarCreate.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-newByCalendar",
      },
    },
    {
      path: "bookings",
      name: "booking-list",
      component: () => import("../pages/Booking/List/BookingList.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-bookings",
      },
    },
    {
      path: "bookings/:id",
      name: "booking-detail",
      component: () => import("../pages/Booking/Detail/BookingDetail.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-bookingsDetail",
      },
    },
    // {
    //     path: 'trip',
    //     name: 'trip',
    //     component: () => import("../pages/Logbook/List.vue"),
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    {
      path: "profile",
      name: "profile",
      component: () => import("../pages/User/Profile.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-profile",
      },
    },
    {
      path: "profile/driving-licence",
      name: "driving-licence",
      component: () => import("../pages/User/DrivingLicence"),
      meta: {
        requiresAuth: true,
        title: "page-title-profile-drivingLicence",
      },
    },
    {
      path: "profile/cost-overview",
      name: "cost-overview",
      component: () => import("../pages/User/CostOverview.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-profile-costOverview",
      },
    },
    {
      path: "profile/invoices",
      name: "invoices",
      component: () => import("../pages/User/Invoices.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-profile-invoices",
      },
    },
    {
      path: "profile/payment-account",
      name: "payment-account",
      component: () => import("../pages/User/PaymentAccount"),
      meta: {
        requiresAuth: true,
        title: "page-title-profile-paymentAccount",
      },
    },
    {
      path: "logbook",
      name: "logbook",
      component: () => import("../pages/Logbook/List.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-logbook",
      },
    },
    {
      path: "profile/languageSelect",
      name: "languageSelect",
      component: () => import("../pages/User/LanguageSelect"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "help",
      name: "help",
      //component: () => import("../pages/Provider/Help.vue"),
      component: () => import("../pages/Provider/HelpSimple.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-help",
      },
    },
    // TODO: chekc if needed /help/:id
    {
      path: "help/:id",
      name: "help-detail",
      component: () => import("../pages/Provider/HelpDetail.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-help-detail",
      },
    },
    // TODO: chekc if needed /provider-select
    {
      path: "provider-select",
      name: "provider-select",
      component: () => import("../pages/Login/ProviderSelect.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "logout",
      name: "logout",
      component: () => import("../pages/Login/Logout.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "notifications",
      name: "notifications",
      component: () => import("../pages/User/Notifications.vue"),
      meta: {
        requiresAuth: true,
        title: "page-title-notifications"
      },
    },
  ],
};

const unauthenticated = {
  path: "",
  redirect: "login",
  component: Unauthenticated,
  children: [
    {
      path: "loading",
      name: "splash",
      component: () => import("../pages/SplashScreen.vue"),
      meta: {
        requiresAuth: false,
        title: "page-title-loading",
      },
    },
    {
      path: "p/:name",
      name: "provider-landing-page",
      component: () => import("../pages/LandingPage.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "default",
      name: "provider-default-page",
      component: () => import("../pages/ProviderDefault.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "login",
      name: "login",
      component: () => import("../pages/Login/Login.vue"),
      meta: {
        requiresAuth: false,
        title: "page-title-login",
      },
    },
    {
      path: "register",
      name: "register",
      component: () => import("../pages/Register/Register.vue"),
      meta: {
        requiresAuth: false,
        title: "page-title-register",
      },
    },
    {
      path: "r/:membership",
      name: "register-private-tariff",
      component: () => import("../pages/LandingPage.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "verify-email",
      name: "verify-email",
      component: () => import("../pages/Login/EmailVerify.vue"),
      meta: {
        requiresAuth: false,
        title: "page-title-verifyEmail",
      },
    },
    {
      path: "reset-password",
      name: "reset-password",
      component: () => import("../pages/Login/ResetPassword.vue"),
      meta: {
        requiresAuth: false,
        title: "page-title-passwordReset",
      },
    },
    {
      path: "registration-payment",
      name: "registration-payment",
      component: () => import("../pages/Register/PaymentRegistration.vue"),
      meta: {
        requiresAuth: false,
        title: "page-title-registrationPayment",
      },
    },
    {
      path: "registration-document-check",
      name: "registration-document-check",
      component: () => import("../pages/Register/DocumentCheckRegistration.vue"),
      meta: {
        requiresAuth: false,
        title: "page-title-doocumentCheckPayment",
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "",
    component: Unauthenticated,
    children: [
      unauthenticated,
      authenticated,
      {
        path: "/:pathMatch(.*)*",
        name: "Error",
        component: () => import("../pages/Login/Login.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      // {
      //     path: "/:pathMatch(.*)*",
      //     name: "Error",
      //     component: () => import("../pages/Errors/Default.vue"),
      //     meta: {
      //         requiresAuth: false
      //     },
      // },
    ],
  },
];

export default routes;
