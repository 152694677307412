import {VueCookieNext} from "vue-cookie-next";

const setup = (api, cache, store) => {

    const appendRequestInterceptors = (instance) => {
        instance.interceptors.request.use(function (config) {
            if (VueCookieNext.isCookieAvailable('pid')) {
                config.headers['PID'] = VueCookieNext.getCookie('pid');
            } else if (!VueCookieNext.isCookieAvailable('auth') && store.getters?.getAuthConfiguration?.id !== undefined) {
                config.headers['PID'] = store.getters.getAuthConfiguration.id;
                VueCookieNext.setCookie('pid', store.getters.getAuthConfiguration.id);
            }
            if (store.getters.getToken) {
                config.headers['Authorization'] = 'Bearer ' + store.getters.getToken;
            }

            // Before request is sent
            return config;
        }, function (error) {
            // On request error
            return Promise.reject(error);
        });

        return instance;
    }

    const appendResponseInterceptors = (instance, cache) => {
        instance.interceptors.response.use(function (response) {
            // When response is received
            if (!response.data.success) {
                new Error('Response not successful');
            }

            return response;
        }, function (error) {
            // On response error

            // when cancelled dont return an error
            if (error.code === 'ERR_CANCELED') return instance;

            // When unauthorized, remove all auth data
            if (error.response.status === 401) {
                sessionStorage.clear();
                if (typeof cache !== "undefined") {
                    cache.reset();
                }
                VueCookieNext.removeCookie('auth');
                // TODO: Check why user can not be used here
                // router.push({name: 'login'});
                location.reload();
            }

            return Promise.reject(error);
        });

        return instance;
    }

    appendRequestInterceptors(api);
    appendResponseInterceptors(api, cache);

}

export default setup;